<template>
  <div id="news-letter" class="rounded border p-4 bg-white">
    <div
        v-if="pending"
        id="pending"
        class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
          variant="danger"
          size="lg"
      />
    </div>
    <b-form
      v-else
      v-on:submit.prevent="save"
    >
      <div class="d-flex flex-column">
        <div v-if="info.regDate" class="text-right">
          <div>
            <b-badge>
              {{ new Date(info.regDate).toLocaleString() }}
            </b-badge>
          </div>
          <div>
            <b-badge>
              {{ info.userId }}
            </b-badge>
          </div>
        </div>
        <div class="text-left">

          <b-form-group
              label="노출 여부"
              label-size="sm"
          >
            <b-form-checkbox
              v-model="info.use"
            >
              {{ info.use ? '노출' : '숨김' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
              label="우선순위 여부"
              label-size="sm"
          >
            <b-form-checkbox
                v-model="info.priority"
            >
              {{ info.priority ? '사용' : '사용안함' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
              label="랜딩 노출 여부"
              label-size="sm"
          >
            <b-form-checkbox
                v-model="info.landing"
            >
              {{ info.landing ? '노출' : '숨김' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="유형"
            label-size="sm"
          >
            <b-form-select
              size="sm"
              v-model="info.kind"
              :options="kindOptions"
            />
          </b-form-group>
          <b-form-group
            label="제목"
            description="제목을 입력해주세요"
            label-size="sm"
          >
            <b-form-input
              size="sm"
              v-model="info.title"
            />
          </b-form-group>
        </div>
        <div class="d-flex">
          <div class="text-left flex-grow-1">
            <b-form-group
                label="썸네일"
                description="URL"
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  v-model="info.image"
              />
            </b-form-group>
          </div>
          <div v-if="info.image" class="ml-4">
            <b-img
              :src="info.image"
              style="max-height: 10rem"
            />
          </div>
        </div>
        <div class="editor p-1">
          <editor
              :api-key="API_KEY"
              :init="EDITOR_OPTIONS"
              v-model="info.content"
          />
        </div>
        <hr/>
        <div class="text-left">
          <div class="d-flex">
            <b-badge
              v-for="(tag, idx) in info.tag"
              :key="`tag-${idx}`"
              class="mx-1"
              v-on:click="removeTag(idx)"
            >
              {{ tag }}
            </b-badge>
          </div>
          <b-form-group
              label="태그"
              description=""
              label-size="sm"
          >
            <b-form-input
                size="sm"
                v-on:keypress.enter.prevent="addTag"
            />
          </b-form-group>
        </div>
      </div>
      <div>
        <b-button
          type="submit"
          size="sm"
          squared
          block
        >
          <font-awesome-icon
            icon="save"
          />
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: "newsLetterDetail",
  components: {
    Editor,
    // EditorMenuBar
  },
  data: () => ({
    pending: false,
    info: {
      _id: null,
      title: null,
      content: null,
      image: null,
      kind: null,
      regDate: null,
      regUser: null,
      regUserName: null,
      userId: null,
      tag: [],
      endDate: null,
      use: false,
      priority: false,
      landing: false
    },
    kindOptions: [],
    editor: null,
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this.info._id = _id
        await this.load()
      }
      await this.kindOption()
    })()
  },
  mounted () {

  },
  beforeDestroy () {
    if (this.editor) this.editor.destroy()
  },
  methods: {
    async kindOption () {
      try {
        const {data} = await this.axios({
          url: `/support/news-letter/kind-option`
        })
        const { kindOptions } = data
        this.kindOptions = kindOptions
      } catch (e) {
      }
    },
    async load() {
      this.pending = true
      try {
        const {data} = await this.axios({
          url: `/support/news-letter/${this.info._id}`,
        })
        const {result, content, title, image, kind, regDate, regUser, regUserName, _id, userId, tag, endDate, use, priority, landing} = data
        if (result) this.info = {content, title, image, kind, regDate, regUser, regUserName, _id, userId, tag, endDate, use, priority, landing}

      } catch (e) {
      }
      this.pending = false
    },
    addTag (evt) {
      const { target } = evt
      const { value } = target
      if (!value) return
      const { tag } = this.info
      if (tag.indexOf(value.trim()) === -1) {
        tag.push(value)
        evt.target.value = ''
      }
    },
    removeTag (idx) {
      const { tag } = this.info
      if (tag[idx]) tag.splice(idx, 1)
    },
    async save () {
      if (!confirm(`${this.info._id ? '수정' : '등록'}하시겠습니까?`)) return
      this.pending = true
      try {
        const { data } = await this.axios({
          url: this.info._id ? `/admin/news-letter/${this.info._id}` : `/admin/news-letter`,
          method: this.info._id ? 'PATCH' : 'POST',
          data: this.info
        })
        const {result, error} = data
        if (result) {
          await this.$router.push({
            path: `/news-letter`
          })
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
      } catch (e) {
      }
      this.pending = false
    }
  }
}
</script>

<style scoped>
  #news-letter {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }
</style>
